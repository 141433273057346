import React from "react"
import { Container } from "components/library"
import { Link, graphql } from "gatsby"
import { useTranslation } from "gatsby-plugin-react-i18next"
import { CtaBlock } from "components/common/cta-block"
import { FooterNewsletter } from "components/library/footer/footer-newsletter"
import NewsletterImage from "images/newsletter-page/newsletter.jpg"

const Newsletter = ({ pageContext: { newsletterMonthYears } }:any) => {
  const { t } = useTranslation()
  const NewslettersContent: any = {}

  var months = ["january", "february", "march", "april", "may", "june",
  	        "july", "august", "september", "october", "november", "december"];

  newsletterMonthYears
  .sort((a:string, b:string)=>{
      return months.indexOf(a.split(' ')[0])
           - months.indexOf(b.split(' ')[0]);
  })
  .reverse()
  .map((mnthYr:string)=>{
    const month = `${mnthYr.split(' ')[0]}`
    const year = `${mnthYr.split(' ')[1]}`
    NewslettersContent[month] = {
      name: `${process.env.GATSBY_LANGUAGE_SELECTED === 'ja' ? "ニュースレター":"Newsletter"} ${month.charAt(0).toUpperCase()}${month.slice(1)} ${year}`,
      image: NewsletterImage,
      month: month,
      mnthYr: mnthYr.split(' ').join('')
    }
  })

  return (
    <main className="-mt-28 sm:-mt-36">
      <Container className="flex flex-col gap-16 pb-24 pt-48">
        <section className="relative flex md:flex-row sm:justify-between sm:flex-col flex-col items-center lg:text-left w-full lg:mx-auto">
          <div className='min-w-[60%] flex flex-col gap-4'>
              <h4 className="text-xl tracking-wider w-48 mb-6 font-medium uppercase sm:text-xl md:text-xl bg-gradient-to-r from-theme-orange from-0% via-theme-orange via-10% to-theme-blue to-100% bg-clip-text text-transparent">
                  {t('newsletter-page.heading')}
              </h4>
              <h2 className="text-2xl tracking-wider sm:text-5xl md:text-5xl font-semibold">
                  {t('newsletter-page.desc')}
              </h2>
              <p className="text-3xl sm:text-3xl sm:max-w-xl md:text-3xl min-w-[95%]">
                  {t('newsletter-page.note')}
              </p>
              <div className="mt-2 min-w-full">
                <FooterNewsletter isOnNewsLetterPage={true}/>
              </div>
          </div>
      </section>
        <div className="h-[1px] sm:block shrink-0 grow bg-gray-200" />
        <section className="flex flex-col md:flex-col">
            <h2 className="text-2xl tracking-wider sm:text-2xl md:text-3xl font-semibold">
                {t('newsletter-page.subHeading')}
            </h2>
            <p className="text-xl sm:text-xl sm:max-w-xl md:text-xl min-w-[95%] mt-4">
                {t('newsletter-page.subDesc')}
            </p>
            <div className="grid md:grid-cols-3 w-full sm:text-left gap-12 mb-20 mt-16">
            {Object.keys(NewslettersContent).map(item => {
            return (
                <Link
                    key={item}
                    to={`/newsletter/${NewslettersContent[item].mnthYr}`}
                    className="flex flex-col w-full rounded-lg shadow-lg gap-2 p-0 justify-center text-center bg-[#5F85EB] hover:shadow-indigo-500/40  hover:text-blue-700 hover:underline"
                    >
                        <img
                            src={NewslettersContent[item].image}
                            className="w-full rounded-t-lg max-h-[100%] bg-[#5F85EB]"
                            alt={NewslettersContent[item].name}
                        />
                        <p className="bg-white md:text-center py-2 rounded-b-lg">
                            {NewslettersContent[item].name}
                        </p>
                </Link>)
            })}
		</div>
        </section>
      </Container>


      <CtaBlock className="md:mt-[100px] lg:mt-0" />
    </main >
  );
}

export default Newsletter

// FOR LANGUAGE TRANSLATION
export const query = graphql`
	query ($language: String!) {
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
	}
`
